import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/payment_successful.png'
import _imports_1 from '@/assets/emoji/emoji_payment_success.png'


const _hoisted_1 = { class: "column items-center payment-authorization-dialog" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[1] || (_cache[1] = _createStaticVNode("<div class=\"payment-dialog-small-body\" data-v-6c71e808><div class=\"col\" data-v-6c71e808><img id=\"payment-success-image\" src=\"" + _imports_0 + "\" class=\"small-dialog-image\" data-v-6c71e808></div><div class=\"col main-user-message\" data-v-6c71e808>Оплата пройшла!</div><div class=\"col user-data-small q-pl-md q-pr-md\" data-v-6c71e808><div id=\"success-message-container\" data-v-6c71e808> Вона настільки успішна, що їй заздрять Маск та Джефф <img id=\"payment-success-emoji\" src=\"" + _imports_1 + "\" style=\"height:15px;\" data-v-6c71e808></div></div></div>", 1)),
    _createVNode(_component_q_btn, {
      ref: "backToStoreButton",
      "no-caps": "",
      class: "dialog-button",
      label: "До магазину",
      onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.handleBackToStoreClick()))
    }, null, 512)
  ]))
}