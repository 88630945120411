
import * as Actions from "@/store/constants/BvrPayCheckout/Actions";
import * as Getters from "@/store/constants/BvrPayCheckout/Getters";
import { computed, ComputedRef } from "vue";
import { useStore } from "vuex";

export default {
  name: "PaymentError",
  setup() {
    const store = useStore();

    const errorCode: ComputedRef<string> = computed(
      () => store.getters[Getters.BVR__GET_APPROVE_ERROR]
    );

    const handleBackToStoreClick = () => {
      store.dispatch(Actions.BVR__REDIRECT_TO_STORE);
    };

    return {
      handleBackToStoreClick,
      errorCode,
    };
  },
};
