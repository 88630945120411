
import * as Actions from "@/store/constants/BvrPayCheckout/Actions";
import { useStore } from "vuex";

export default {
  name: "PaymentFail",
  setup() {
    const store = useStore();

    const handleBackToStoreClick = () => {
      store.dispatch(Actions.BVR__REDIRECT_TO_STORE);
    };

    return {
      handleBackToStoreClick,
    };
  },
};
