import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/payment_failed.png'


const _hoisted_1 = { class: "column items-center payment-authorization-dialog" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[1] || (_cache[1] = _createStaticVNode("<div class=\"payment-dialog-small-body\" data-v-b8fda582><div class=\"col\" data-v-b8fda582><img src=\"" + _imports_0 + "\" class=\"small-dialog-image\" data-v-b8fda582></div><div class=\"col main-user-message\" data-v-b8fda582>Хочеш продовжити покупку?</div><div class=\"col user-data-small q-pa-md\" data-v-b8fda582><span data-v-b8fda582>Повернися до магазину та обери інший спосіб оплати</span></div></div>", 1)),
    _createVNode(_component_q_btn, {
      ref: "backToStoreButton",
      "no-caps": "",
      id: "store-redirect-button",
      class: "dialog-button user-button",
      label: "До магазину",
      onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.handleBackToStoreClick()))
    }, null, 512)
  ]))
}